























































































































import { defineComponent } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { minutesToMilliseconds } from "date-fns";
import { DateTime } from "klokwerk";
import {
	mapActions as mapPiniaActions,
	mapState as mapPiniaState,
	mapWritableState as mapPiniaWritableState,
} from "pinia";
import type { IomodelsLidVoorFeed } from "@/lib/backend/club.api";
import { getCoachName } from "@/lib/getCoachName";
import { queryOptions_coaches } from "@/lib/query/functions/coaches";
import { useLiveFeedStore } from "@/pinia/liveFeed";
import { useLocationStore } from "@/pinia/location";
import { getTimeOfDay } from "@/utils/date";
import { getIconName, getMemberImage } from "@/utils/images";

type Data = {
	loading: boolean;
	interval?: ReturnType<typeof setInterval>;
	sections: Array<{ label: string; filterRule: (lid: IomodelsLidVoorFeed) => boolean }>;
};

export default defineComponent({
	setup() {
		const { data: coaches } = useQuery(queryOptions_coaches());

		return { coaches, getCoachName };
	},
	data(): Data {
		return {
			loading: false,
			interval: undefined,
			sections: [
				{
					label: "In de club",
					filterRule: (lid: IomodelsLidVoorFeed) => {
						const before = new DateTime().setHours((current) => current.hours - 1);
						const timestamp = new DateTime(lid.timestamp);

						return timestamp >= before;
					},
				},
				{
					label: "Waarschijnlijk al weg",
					filterRule: (lid: IomodelsLidVoorFeed) => {
						const before = new DateTime().setHours((current) => current.hours - 1);
						const timestamp = new DateTime(lid.timestamp);

						return timestamp < before;
					},
				},
			],
		};
	},
	computed: {
		...mapPiniaWritableState(useLiveFeedStore, {
			coachingsGroep: "coachingsGroep",
		}),
		...mapPiniaState(useLiveFeedStore, {
			filteredByCoachingsGroep: "byPersonalPreference",
		}),
	},
	async created() {
		this.loading = true;

		await this.initLiveFeed();
		await this.initLocation();

		this.interval = setInterval(() => {
			this.getLiveFeed();
		}, minutesToMilliseconds(1));

		this.loading = false;
	},
	async destroyed() {
		if (this.interval) clearInterval(this.interval);
	},

	methods: {
		...mapPiniaActions(useLocationStore, {
			initLocation: "init",
		}),
		...mapPiniaActions(useLiveFeedStore, {
			initLiveFeed: "init",
			getLiveFeed: "get",
		}),
		getMemberImage,
		getIconName,
		getTimeOfDay,
		DateTime,
	},
});
